import {IngredientOverviewSectionCategory} from "./ingredient-overview-section-category";
import {ViewModel} from "../../../../../shared/building_blocks/view_model";
import IngredientOverviewItem from "./ingredient-overview-item";

export interface IngredientOverviewSectionProps {
    section_id: IngredientOverviewSectionCategory,
    title: string,
    intro: string
    items: IngredientOverviewItem[]
}

export default class IngredientOverviewSection extends ViewModel<IngredientOverviewSectionProps> {

    private constructor(props: IngredientOverviewSectionProps) {
        super(props);
    }

    public static create(props: IngredientOverviewSectionProps): IngredientOverviewSection {
        return new IngredientOverviewSection({...props})
    }

}

