interface ViewModelProps {
	[index: string]: any;
}


export abstract class ViewModel<T extends ViewModelProps> {
	public props: T;

	protected constructor(props: T) {
		const baseProps: any = {
			...props,
		}

		this.props = baseProps;
	}

}
