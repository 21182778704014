import React from "react";
import IngredientOverviewSection from "../../model/ingredient-overview-section";
import IngredientOverviewCardWithIllustration from "../cards/ingredient-overview-card-with-illustration";
import {GatsbyImage} from "gatsby-plugin-image";
import {DotsHorizontalIcon, PhotographIcon} from "@heroicons/react/outline";
import {IngredientOverviewSectionCategory} from "../../model/ingredient-overview-section-category";

type IngredientOverviewIllustrationSectionProps = {
    section: IngredientOverviewSection
}

export default function IngredientOverviewIllustrationSection({section}: IngredientOverviewIllustrationSectionProps): JSX.Element {

    return (
        <div className="my-8">

            <div className="flex justify-between p-2 bg-gray-100/60 rounded-lg ">
                <div>
                    <h2 id={section.props.title} className="text-2xl font-bold">
                        {section.props.title}
                    </h2>
                </div>
                <div>
                    <h3 className="text-2xl font-bold text-salmonRed">
                        {SectionNameOnJapanese(section.props.section_id)}
                    </h3>
                </div>
            </div>

            <div className="p-2">
                <p>{section.props.intro}</p>
            </div>

            {/* Column names */}
            <div className="flex items-center gap-4 border-b border-black p-2">

                <div className="flex justify-center items-center w-[84px] mr-4">
                    <PhotographIcon className="h-4 text-center"/>
                </div>

                <div className="flex-1">
                    <span className="font-bold">Names</span>
                </div>
                <div className="flex-none justify-self-center">
                    <DotsHorizontalIcon className="h-5"/>
                </div>

            </div>

            {/* Ingredients items */}
            <div className="grid grid-cols-1 divide-y border-b border-black p-2">
                {section.props.items
                    .map((item, index) => {
                        if (item.props.include_in_overview) {
                            return (
                                <div key={index}>
                                    <IngredientOverviewCardWithIllustration item={item} />
                                </div>

                            )
                        }
                })}
            </div>

        </div>
    )
}

function SectionNameOnJapanese(currentSection: IngredientOverviewSectionCategory): string {
    switch (currentSection) {
        case IngredientOverviewSectionCategory.Fish:
            return "魚類";
        case IngredientOverviewSectionCategory.Crustacean:
            return "甲殻類";
        case IngredientOverviewSectionCategory.Cephalopod:
            return "頭足類";
        case IngredientOverviewSectionCategory.Bivalvia:
            return "二枚貝";
        case IngredientOverviewSectionCategory.SnailOrSlug:
            return "腹足綱";
        case IngredientOverviewSectionCategory.VegetableOrFruit:
            break;
        case IngredientOverviewSectionCategory.Roe:
            return "真子";
        case IngredientOverviewSectionCategory.Other:
            return "その他";
        default:
            return ""
    }
    return "";
}