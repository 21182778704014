import {ViewModel} from "../../../../../shared/building_blocks/view_model";
import {IngredientsOverviewResponse} from "../../infrastructure/dto/ingredient-overview-graphql-response";
import IngredientOverviewSection, {IngredientOverviewSectionProps} from "./ingredient-overview-section";
import {
    IngredientOverviewSectionCategoryFactory,
    IngredientOverviewSectionCategory
} from "./ingredient-overview-section-category";
import IngredientOverviewItem, {IngredientOverviewItemProps} from "./ingredient-overview-item";



export interface IngredientOverviewViewModelProps {
    slug: string
    language: string
    meta: {
        title: string,
        description: string,
        keywords: string
    }
    dates: {
        created: Date
        updated: Date | null
    }
    status: string,
    content: {
        heading_h1: string,
        heading_h2: string | null,
        intro: string | null,
        overview: {
            table: {
                sections: IngredientOverviewSection[]
            }
        }
    }
}

export default class IngredientOverviewViewModel extends ViewModel<IngredientOverviewViewModelProps> {

    private constructor(props: IngredientOverviewViewModelProps) {
        super(props);
    }

    public static create(props: IngredientOverviewViewModelProps): IngredientOverviewViewModel {
        return new IngredientOverviewViewModel({...props})
    }

}