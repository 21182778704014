import IngredientOverviewViewModel from "../../presentation/model/ingredient-overview-view-model";
import {
    IngredientOverviewGraphqlToViewModelFactory
} from "../../presentation/factory/ingredient-overview-graphql-to-view-model-factory";
import {IngredientsOverviewResponse} from "../../infrastructure/dto/ingredient-overview-graphql-response";

export class IngredientOverviewGraphQlToViewModel {

    execute(data: IngredientsOverviewResponse): IngredientOverviewViewModel {
        return new IngredientOverviewGraphqlToViewModelFactory(data).build();
    }
}