import {
    ImagesOverviewTableEdge,
    Ingredient,
    IngredientsOverviewResponse, PageIngredientsOverview, PageIngredientsOverviewTranslation
} from "../../infrastructure/dto/ingredient-overview-graphql-response";
import IngredientOverviewViewModel, {IngredientOverviewViewModelProps} from "../model/ingredient-overview-view-model";
import IngredientOverviewSection, {IngredientOverviewSectionProps} from "../model/ingredient-overview-section";
import {
    IngredientOverviewSectionCategoryFactory,
    IngredientOverviewSectionCategory
} from "../model/ingredient-overview-section-category";
import IngredientOverviewItem from "../model/ingredient-overview-item";
import {IngredientOverviewGraphqlToItemFactory} from "./ingredient-overview-graphql-to-item-factory";

export class IngredientOverviewGraphqlToViewModelFactory {
    private page_data: PageIngredientsOverview;
    private page_translation: PageIngredientsOverviewTranslation;
    private readonly ingredients_data: Ingredient[];
    private readonly images_table_overview_edges: ImagesOverviewTableEdge[];


    constructor(response: IngredientsOverviewResponse) {
        // localized by filtered graphql query
        this.page_data = response.directus.page_ingredients_overview[0];
        this.page_translation = this.page_data.translations[0];
        this.ingredients_data = response.directus.ingredients;
        this.images_table_overview_edges =response.images_overview_table.edges;
    }

    public build(): IngredientOverviewViewModel {
        const props: IngredientOverviewViewModelProps = {
            slug: this.page_translation.page_slug,
            status: this.page_translation.status,
            dates: this.buildDates(),
            language: this.buildLanguage(),
            meta: this.buildMeta(),
            content: this.buildPageContent()
        }
        return IngredientOverviewViewModel.create(props);
    }

    private buildPageContent() {
        return {
            heading_h1: this.page_translation.content_heading_h1,
            heading_h2: this.page_translation.content_heading_h2,
            intro: this.page_translation.content_intro,
            overview: {
                table: {
                    sections: this.buildTableSections()
                }
            }
        }
    }

    private buildTableSections(): IngredientOverviewSection[] {
        return this.page_translation.content_table_sections.map((raw_section) => {
            const currentSection = IngredientOverviewSectionCategoryFactory.create_from_string(raw_section.id);
            const props: IngredientOverviewSectionProps = {
                section_id: currentSection,
                title: raw_section.title,
                intro: raw_section.intro,
                items: this.buildOverviewItemsForCurrentSection(currentSection)
            }
            return IngredientOverviewSection.create(props);
        } )
    }

    private buildOverviewItemsForCurrentSection(section: IngredientOverviewSectionCategory): IngredientOverviewItem[] {
        const factory = new IngredientOverviewGraphqlToItemFactory(section, this.ingredients_data, this.images_table_overview_edges);
        return factory.build()
    }


    private buildDates() {
        return {
            created: this.page_data.date_created,
            updated: this.page_data.date_updated
        }
    }

    private buildLanguage() {
        return this.page_translation.languages_code.locale
    }

    private buildMeta() {
        return {
            description: this.page_translation.page_description,
            keywords: this.page_translation.page_keywords.join(", "),
            title: this.page_translation.page_title
        }
    }

}