import {
    EntitiesAuthentic, EntityMain, EntityMainTranslation,
    ImagesOverviewTableEdge,
    Ingredient
} from "../../infrastructure/dto/ingredient-overview-graphql-response";
import IngredientOverviewItem, {IngredientOverviewItemProps} from "../model/ingredient-overview-item";
import {IngredientOverviewSectionCategory} from "../model/ingredient-overview-section-category";
import {
    IngredientEntityCommonNameViewModel,
    IngredientEntityCommonNameViewModelProps
} from "../../../article/presentation/model/ingredient-entity-common-name-view-model";

export class IngredientOverviewGraphqlToItemFactory {
    private readonly section: IngredientOverviewSectionCategory;
    private ingredients: Ingredient[];
    private images_table_overview: ImagesOverviewTableEdge[];

    constructor(
        section: IngredientOverviewSectionCategory,
        ingredients_data: Ingredient[], // Graph Response
        images_table_overview_edges: ImagesOverviewTableEdge[] // Graph Response
    ) {
        this.section = section;
        this.ingredients = ingredients_data;
        this.images_table_overview = images_table_overview_edges;
    }

    public build(): IngredientOverviewItem[] {
        const items_filtered = this.ingredients.filter((ingredient) => ingredient.category === this.section)
        return items_filtered.map((ingredient) => {
            const slug = ingredient.umbrella_slug;
            const image = this.images_table_overview.filter((edge) => edge.node.relativeDirectory === `ingredients/${slug}/overview/table`)[0];
            const props: IngredientOverviewItemProps = {
                status: ingredient.status,
                include_in_overview: ingredient.include_in_overview,
                collective_term: ingredient.collective_term,
                image_edge: image,
                names: this.mapNames(ingredient.entity_main),
                slug: slug,
                title: ingredient.title
            }
            return IngredientOverviewItem.create(props);
        })
    }

    private mapNames(entity: EntityMain): IngredientEntityCommonNameViewModel[] {
        if (entity) {
            const entity_translations = entity.translations;

            if (entity_translations) {
                return entity_translations.map((entity_trans) => {
                    const props: IngredientEntityCommonNameViewModelProps = {
                        commonName: entity_trans.name,
                        isPreferred: entity_trans.is_preferred,
                        locale: entity_trans.languages_code.locale,
                        script: entity_trans.script,
                        sort: entity_trans.sort ?? 9999
                    }
                    return IngredientEntityCommonNameViewModel.create(props);
                })
            }
        }
        return []
    }

}