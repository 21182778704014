export enum IngredientOverviewSectionCategory {
    Fish = "fish",
    Crustacean = "crustacean",
    Cephalopod = "cephalopod",
    Bivalvia = "bivalvia",
    SnailOrSlug = "snail_or_slug",
    VegetableOrFruit = "vegetable_or_fruit",
    Roe = "roe",
    Other = "other"
}

export class IngredientOverviewSectionCategoryFactory {
    public static create_from_string(input: string): IngredientOverviewSectionCategory {
        if (input === "fish") {
            return IngredientOverviewSectionCategory.Fish
        }

        if (input === "crustacean") {
            return IngredientOverviewSectionCategory.Crustacean
        }

        if (input === "cephalopod") {
            return IngredientOverviewSectionCategory.Cephalopod
        }

        if (input === "bivalvia") {
            return IngredientOverviewSectionCategory.Bivalvia
        }

        if (input === "snail_or_slug") {
            return IngredientOverviewSectionCategory.SnailOrSlug
        }

        if (input === "vegetable_or_fruit") {
            return IngredientOverviewSectionCategory.VegetableOrFruit
        }

        if (input === "roe") {
            return IngredientOverviewSectionCategory.Roe
        }

        if (input === "other") {
            return IngredientOverviewSectionCategory.Other
        }

        throw Error(`Could not create IngredientOverviewSection from input string ${input}. Wrong type or spelling error?`);
    }
}