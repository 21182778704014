import React from "react";
import IngredientOverviewItem from "../../model/ingredient-overview-item";
import {GatsbyImage, getImage, IGatsbyImageData} from "gatsby-plugin-image";
import {navigate} from "gatsby";
import {useI18next} from "gatsby-plugin-react-i18next";
import {InformationCircleIcon} from "@heroicons/react/solid";
import ReactCountryFlag from "react-country-flag";


interface IngredientOverviewCardIllustrationProps {
    item: IngredientOverviewItem
}

export default function IngredientOverviewCardWithIllustration({item}: IngredientOverviewCardIllustrationProps) {

    const {language, t} = useI18next()

    // const japanese_names = item.props.names
    //     .filter((name) => ((name.props.locale === "ja") && (name.props.script === "latin") && (name.props.isPreferred)))
    //     .map((name) => name.props.commonName)
    //     .join(", ")

    const common_names = item.props.names
        .filter((name) => ((name.props.locale === language)))
        .sort((a, b) => (a.props.commonName < b.props.commonName) ? -1 : 1)
        .slice(0, 3)
        .map((name) => name.props.commonName)
        .join(", ")

    const image_data = (): IGatsbyImageData | undefined => {
        if (item.props.image_edge) {
            return getImage(item.props.image_edge.node);
        }
    }

    const NonProductionWarning = (): JSX.Element => {
        const status = item.props.status;
        return (
            <>
                {(status === "draft") &&
                    <button className="mx-4 px-4 py-0 bg-red-600 hover:bg-red-700 text-white text-sm font-medium rounded-full">
                        Draft
                    </button>
                }
                {(status === "archived") &&
                    <button className="mx-4 px-4 py-0 bg-blue-600 hover:bg-red-700 text-white text-sm font-medium rounded-full">
                        Archived
                    </button>
                }
            </>
        )
    }

    const illustration = image_data()

    const uri = `/${language}/sushi/${item.props.slug}`;

    const onClick = async () => {
        await navigate(uri);
    }

    return (
        <>
            <div className="max-w rounded overflow-hidden hover:bg-gray-50 py-2" onClick={onClick}>

                <div className="flex items-center gap-4">
                    <div className="flex-none w-fill mr-4" id="image">
                        {illustration && <GatsbyImage className={"rounded"}
                                                    alt={t("Ingredients_Overview.Illustration.alt", { name: item.props.title })}
                                                    image={illustration}/>}
                    </div>
                    <div className="flex-1">
                        <div>
                            <span className="font-bold">
                                {item.props.title}
                                {/*<ReactCountryFlag countryCode="Jp" />*/}
                            </span>
                            <NonProductionWarning/>
                        </div>
                        <div>
                            <span className="font-light">
                                {capitalize(common_names)}
                            </span>
                        </div>
                    </div>
                    <div className="flex-none justify-self-center">
                        <a href={uri} title={t("Ingredients_Overview.Information.title", { name: item.props.title })}>
                            <InformationCircleIcon className="h-5 text-salmonRed"/>
                        </a>
                    </div>
                </div>

            </div>
        </>
    )
}

function capitalize(s: string) {
    return s && s[0].toUpperCase() + s.slice(1);
}