import IngredientOverviewViewModel from "../../model/ingredient-overview-view-model";
import {useI18next} from "gatsby-plugin-react-i18next";
import {GatsbySeo} from "gatsby-plugin-next-seo";
import React from "react";
import metaPublisherJson from "../../../../../../shared/seo/presentation/seo-meta-publisher";

export interface IngredientOverviewSeoProps {
    viewModel: IngredientOverviewViewModel
}

export default function IngredientOverviewSeo({viewModel}: IngredientOverviewSeoProps) {

    const {language, t} = useI18next();

    const url = `${t("Site.url")}/${language}/sushi/${viewModel.props.slug}`;
    const metaTags = metaPublisherJson()

    const languageAlternates = [
        {
            hrefLang: 'de',
            href: t("Site.url") + "/de/sushi-zutaten"
        },
        {
            hrefLang: 'en',
            href: t("Site.url") + "/en/sushi-ingredients"
        }];

    return (
        <>
            <GatsbySeo
                language={language}
                title={viewModel.props.meta.title}
                description={viewModel.props.meta.description}
                languageAlternates={languageAlternates}
                metaTags={metaTags}
                canonical={url}
            />
        </>
    )

}