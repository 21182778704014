import {graphql} from "gatsby";
import Layout from "../components/layout";
import React from "react";
import IngredientOverviewPageContent from "../modules/ingredients/submodules/overview/presentation/page/ingredient-overview-page-content";
import {useAppStore} from "../state/app_state";
import {
    IngredientOverviewGraphQlToViewModel
} from "../modules/ingredients/submodules/overview/application/usecase/ingredient-overview-graphql-to-view-model";
import IngredientOverviewSeo from "../modules/ingredients/submodules/overview/presentation/components/seo/ingredient-overview-seo";


/*

{
    "regex_images_overview_table": "/ingredients/!*.*!/overview/table/",
    "language":"en",
  	"flavourPubLevel": ["published"]
}


*/

export const query = graphql`
query ($language: String!, $regex_images_overview_table: String!, $flavourPubLevel: [String]) {
  directus {
    page_ingredients_overview {
      id
      status
      translations(
        filter: {
          languages_code: {locale: {_eq: $language}}
        }
      ) {
        id
        status
        page_slug
        page_title
        page_keywords
        page_description
        content_heading_h1
        content_heading_h2
        content_intro
        content_table_sections
        languages_code {
          code
          locale
        }
      }
    }
    ingredients(
      sort: "title", 
      filter: {
        status: {_in: $flavourPubLevel}
      }
    ) {
      id
      umbrella_slug
      category
      status
      include_in_overview
      title
      collective_term
      translations(
        filter: {
          languages_code: {locale: {_eq: $language}},
          status: {_in: $flavourPubLevel},
        }
      ) {
        id
        intro
        ingredients_id {
          id
          status
          title
          umbrella_slug
        }
      }
      entity_main {
        id
          status
          title
          translations(sort: "sort") {
            id
            status
            sort
            is_preferred
            script
            name
            name_latin
            languages_code {
              code
              locale
              name
            }
          }
      }
    }
  }
  images_overview_table: allFile(
    filter: {sourceInstanceName: {eq: "media"}, extension: {in: ["jpg", "png"]}, relativeDirectory: {regex: $regex_images_overview_table}}
  ) {
    edges {
      node {
        id
        sourceInstanceName
        name
        relativePath
        relativeDirectory
        publicURL
        ext
        extension
        dir
        root
        base
        absolutePath
        childImageSharp {
          id
          gatsbyImageData(width: 84, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }
    }
  }
  locales: allLocale(filter: {language: {eq: $language}}) {
    edges {
      node {
        ns
        data
        language
      }
    }
  }
}
`

export default function IngredientOverviewTemplatePage({data}: any) {

    // State
    const setIngredientSection = useAppStore(state => state.setIngredients);
    setIngredientSection()

    const useCase = new IngredientOverviewGraphQlToViewModel();
    const viewModel = useCase.execute(data);

    return (
        <>
            <IngredientOverviewSeo viewModel={viewModel}/>
            <Layout>
                <IngredientOverviewPageContent viewModel={viewModel}/>
                {/*<pre className="bg-white">{JSON.stringify(data, null, 4)}</pre>*/}

            </Layout>
        </>
    )
}
