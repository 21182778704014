import {IngredientEntityCommonNameViewModel} from "../../../article/presentation/model/ingredient-entity-common-name-view-model";
import {ViewModel} from "../../../../../shared/building_blocks/view_model";

export interface IngredientOverviewItemProps {
    status: string
    image_edge: any
    collective_term: string | any
    include_in_overview: boolean
    slug: string
    title: string
    names: IngredientEntityCommonNameViewModel[]
}

export default class IngredientOverviewItem extends ViewModel<IngredientOverviewItemProps> {

    private constructor(props: IngredientOverviewItemProps) {
        super(props);
    }

    public static create(props: IngredientOverviewItemProps): IngredientOverviewItem {
        return new IngredientOverviewItem({...props})
    }

}

