import React from "react";
import IngredientOverviewViewModel from "../model/ingredient-overview-view-model";
import IngredientOverviewIllustrationSection
    from "../components/section/ingredient-overview-illustration-section";
import {useI18next} from "gatsby-plugin-react-i18next";

export type IngredientOverviewPageContentProps = {
    viewModel: IngredientOverviewViewModel
}


export default function IngredientOverviewPageContent({viewModel}: IngredientOverviewPageContentProps): JSX.Element {

    const {t} = useI18next();

    const sections = viewModel.props.content.overview.table.sections;
    if (sections.length > 0) {
        return (
            <>


                <div className="ingredient-background-hero">
                    <div className="layout-containerized font-sans">
                        <div className=" mx-auto grid grid-cols-12 items-center lg:gap-4 lg:items-end">

                            {/* Left */}
                            <div className="col-span-12 lg:col-span-6">
                                <div className="flex flex-col w-full justify-center items-start my-4 ">
                                    <h3 className="text-base text-white font-bold">寿司ネタ</h3>
                                    <h1 className="text-2xl md:text-3xl lg:text-4xl text-white font-bold pb-2">Sushi &
                                        Sashimi
                                        Ingredients</h1>

                                    <div className="bg-white rounded-lg drop-shadow-lg p-5">
                                        <p className="sm:visible text-black font-light">
                                            {t("Ingredients_Overview.intro")}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            {/* Right */}
                            <div className="col-span-12 lg:col-span-6">
                                <div className="bg-white rounded-lg drop-shadow-lg p-5 mb-4">
                                    {/*<h4 className="text-salmonRed font-bold">Start Exploring Ingredients</h4>*/}

                                    <div className="grid grid-cols-2 sm:grid-cols-3 gap-4  content-center">
                                        {viewModel.props.content.overview.table.sections.map((section, index) => {
                                            if (section.props.items.length > 0) {
                                                return (
                                                    <button key={index}
                                                            className="flex items-center justify-center focus:outline-none text-white text-sm sm:text-base bg-salmonRed hover:bg-salmonRed-dark rounded py-2 w-full transition duration-150 ease-in">
                                                        <a href={`#${section.props.title}`}>
                                                            <span className="uppercase text-xs font-bold break-words">{section.props.title}</span>
                                                        </a>
                                                    </button>
                                                )
                                            }
                                        })}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="layout-containerized font-sans px-4 lg:px-2">
                    {sections.map((section, index) => {
                            return (
                                section.props.items.length > 0 &&
                                <div key={index}>
                                    <IngredientOverviewIllustrationSection section={section}/>
                                </div>
                            )
                        }
                    )}
                </div>
            </>
        )
    } else {
        return (<></>)
    }


}